import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../AuthProvider';
import * as helpers from '../../utils/helpers.js';
import FormHelperText from '@mui/material/FormHelperText';
import ReCAPTCHA from 'react-google-recaptcha';

const theme = createTheme();

export default function SignInPage() {
    const { onLogin, siteKey, recaptchaRef } = React.useContext(AuthContext);
    const [userMessage, setUserMessage] = React.useState('');
    const [passwordValue, setPasswordValue] = React.useState('');
    const [completedReCaptcha, setCompletedReCaptcha] = React.useState(false);

    React.useEffect(() => {
        async function fillPasswordFromCache() {
            const cachedPass = helpers.getCookie('cachedPass');
            setPasswordValue(cachedPass);
        }

        //users without a cached token still get a first-time assist (happens because past version did not have tokens or token caching)
        fillPasswordFromCache();
    }, []);

    const checkCompletedReCaptcha = React.useCallback(() => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue) setCompletedReCaptcha(true);
    }, [recaptchaRef]);

    const handleSignIn = async event => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        document.cookie = `cachedPass=${data.get('password')}`;
        let success = await onLogin(data.get('password'));

        if (!success) {
            setUserMessage('Incorrect Password!');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Avatar sx={{ m: 3, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Box component="form" noValidate onSubmit={handleSignIn} sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    value={passwordValue}
                                    onChange={event => setPasswordValue(event.target.value)}
                                    error={userMessage !== ''}
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormHelperText error={true} className="d-flex justify-content-center">
                                    {userMessage}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={!completedReCaptcha}
                            sx={{ mt: 3, mb: 3 }}>
                            Sign In
                        </Button>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={siteKey}
                                onChange={() => checkCompletedReCaptcha()}
                                onExpired={() => setCompletedReCaptcha(false)}
                                size="compact"
                            />
                        </div>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
