import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AuthProvider from './AuthProvider';
import SignInPage from './components/SignInPage/SignInPage';
import ReferralPage from './components/ReferralPage/ReferralPage';
import ProtectedRoute from './ProtectedRoute.jsx';
import './custom.css';

const App = () => {
    const location = useLocation();

    return (
        <>
            <AuthProvider>
                <Routes>
                    <Route index element={<SignInPage />} />
                    <Route path="sign-in" element={<SignInPage />} />
                    <Route
                        path="referral"
                        element={
                            <ProtectedRoute>
                                <ReferralPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/sign-in" replace state={{ from: location }} />} />
                </Routes>
            </AuthProvider>
        </>
    );
};

export default App;
