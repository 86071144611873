export class ApiConfig {
    _config;
    constructor() {
        if (ApiConfig._instance) {
            return ApiConfig._instance;
        }
        ApiConfig._instance = this;
    }
    async get() {
        if (!this._config) {
            const response = await fetch('/_config');

            if (!response.ok) throw new Error('Failed to fetch config');

            const config = await response.json();
            config.applicationUrl =
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? `:${window.location.port}` : '');

            this._config = config;
        }

        return this._config;
    }

    static get instance() {
        return apiConfig;
    }
}

const apiConfig = new ApiConfig();
export default apiConfig;
