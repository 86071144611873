import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import React from 'react';

const ProtectedRoute = ({ children }) => {
    const { token } = React.useContext(AuthContext);
    const location = useLocation();

    if (!token) {
        return <Navigate to="/sign-in" replace state={{ from: location }} />;
    }

    return children;
};

export default ProtectedRoute;
