import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as helpers from '../../utils/helpers.js';
import OutlinedInput from '@mui/material/OutlinedInput';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Validate, AutoDisabler, ValidationGroup } from 'mui-validate';
import { AuthContext } from '../../AuthProvider.jsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import dayjs from 'dayjs';

const theme = createTheme();
// constants matching column definitions:
const MAX_NAME_STRING_LEN = 150; // coach, contact, city, shop
const MAX_EMAIL_STRING_LEN = 100; // coach and contact
const MAX_PHONE_STRING_LEN = 125;
// note: we assume that they won't exceed nvarchar(max) for additional info
const MAX_DOMAIN_EXTENSION_LEN = 4; // .com, .net, etc.

export default function ReferralPage() {
    const { token, getSettings } = React.useContext(AuthContext);
    const linkedRef = React.useRef();
    const now = dayjs();

    const [coachName, setCoachName] = React.useState('');
    const [coachEmail, setCoachEmail] = React.useState('');
    const [shopName, setShopName] = React.useState('');
    const [shopState, setShopState] = React.useState('');
    const [shopCity, setShopCity] = React.useState('');
    const [contactName, setContactName] = React.useState('');
    const [contactEmail, setContactEmail] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');
    const [additionalDetails, setAdditionalDetails] = React.useState('');
    const [availableNow, setAvailableNow] = React.useState(false);
    const [specifyTime, setSpecifyTime] = React.useState(false);
    const [shopAvailabilityDate, setShopAvailabilityDate] = React.useState(dayjs(now.format('YYYY-MM-DD'))); //remove time of day from new date
    const [userMessage, setUserMessage] = React.useState('');
    const [userMessageColour, setUserMessageColour] = React.useState('primary.main'); //blue

    const formIsInvalid =
        coachName === '' ||
        coachEmail === '' ||
        shopName === '' ||
        shopState === '' ||
        shopState === ' ' ||
        contactName === '' ||
        contactEmail === '' ||
        (!availableNow && !(shopAvailabilityDate > now));

    const formIsUntouched =
        (coachName === '' || coachName === helpers.getCookie('coachName')) &&
        (coachEmail === '' || coachEmail === helpers.getCookie('coachEmail')) &&
        shopName === '' &&
        contactName === '' &&
        contactEmail === '';

    const isUsingDefaultDate = shopAvailabilityDate.format('DD/MM/YYYY') === now.format('DD/MM/YYYY');

    // manually detect user skipping shop state
    const skippedShopState =
        // filled inputs above
        coachName !== '' &&
        coachEmail !== '' &&
        // shop state is blank or reset state
        (shopState === '' || shopState === ' ') &&
        // touched inputs below
        (shopCity !== '' || contactName !== '' || contactEmail !== '' || (availableNow && shopAvailabilityDate > now));

    const filledAllRequiredExceptDate =
        // filled inputs above
        coachName !== '' &&
        coachEmail !== '' &&
        shopState !== '' &&
        contactName !== '' &&
        contactEmail !== '' &&
        shopAvailabilityDate.format('DD/MM/YYYY') === now.format('DD/MM/YYYY');

    const clearForm = () => {
        const availableNowStateRecord = availableNow;
        const specifyTimeStateRecord = specifyTime;

        setShopName('');
        setShopState(' ');
        setShopCity('');
        setContactName('');
        setContactEmail('');
        setContactPhone('');
        setAdditionalDetails('');

        //reset checkbox states before date is restored to default
        if (availableNowStateRecord === true) document.getElementById('availableNowCheckBox').click();
        if (specifyTimeStateRecord === true) document.getElementById('specifyTimeCheckBox').click();

        setShopAvailabilityDate(dayjs(now.format('YYYY-MM-DD')));
    };

    React.useEffect(() => {
        //pull previously entered data from cookies if it exists
        if (helpers.getCookie('coachName')) setCoachName(helpers.getCookie('coachName'));

        if (helpers.getCookie('coachEmail')) setCoachEmail(helpers.getCookie('coachEmail'));
    }, []);

    async function createSalesReferral() {
        const settings = await getSettings();

        const url = `${settings.apiUrl}/api/security/SalesReferral`;
        return await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'token ' + token,
            },
            // truncate values to validator lengths as a precaution
            body: JSON.stringify({
                CoachName: coachName.substring(0, MAX_NAME_STRING_LEN - 1),
                CoachEmail: coachEmail.substring(0, MAX_EMAIL_STRING_LEN),
                ShopName: shopName.substring(0, MAX_NAME_STRING_LEN),
                State: shopState, // from dropdown, no need to truncate
                ShopCity: shopCity.substring(0, MAX_NAME_STRING_LEN),
                ShopMainContactName: contactName.substring(0, MAX_NAME_STRING_LEN),
                ShopMainContactEmail: contactEmail.substring(0, MAX_EMAIL_STRING_LEN),
                ShopMainContactPhone: contactPhone.substring(0, MAX_PHONE_STRING_LEN),
                ShopAvailability: availableNow
                    ? 'ASAP'
                    : specifyTime
                    ? shopAvailabilityDate
                    : shopAvailabilityDate.format('DD/MM/YYYY'),
                AdditionalInformation: additionalDetails,
            }),
        });
    }

    const clearUserMessage = () => {
        setUserMessage('');
    };

    const handleSubmit = async event => {
        event.preventDefault();
        document.cookie = `coachName=${coachName}`;
        document.cookie = `coachEmail=${coachEmail}`;

        const response = await createSalesReferral();
        if (response.ok) {
            setUserMessage('Referral Submitted');
            clearForm();
            setTimeout(clearUserMessage, 12000);
        } else {
            setUserMessage('Referral not Submitted - Please refresh page');
        }

        // present user feedback
        setUserMessageColour(response.ok ? '#1976d2' : '#cc414c');
        const myElement = document.getElementById('userMessage');
        myElement.scrollIntoView({ behavior: 'smooth' });
    };

    const checkValidInputLength = (input, maxLength) => {
        return maxLength >= input.length;
    };

    const checkEmailInvalidInStages = input => {
        // easy check 1: correct length
        if (!checkValidInputLength(input, MAX_EMAIL_STRING_LEN)) return false;

        // easy check 2: has @ symbol
        if (input.indexOf('@') === -1) return false;

        // easy check 3: has . symbol
        if (input.indexOf('.') === -1) return false;

        // easy check 4: length of domain extension < 4

        // get the substring after the . symbol in the domain, get the length of that substring
        const domainExtension = input.substring(input.indexOf('@') + 1);
        const domainExtensionLength = domainExtension.substring(domainExtension.indexOf('.') + 1).length;
        if (domainExtensionLength > MAX_DOMAIN_EXTENSION_LEN || domainExtensionLength === 0) return false;

        // easy-ish check: has valid domain
        const hasValidDomain = /(?:\w+\.)*\w+@(?:\w+\.)+\w{2,4}/;
        if (!hasValidDomain.test(input)) return false;

        // harder check: is valid (can cause a lot of backtracking)
        const isValidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return isValidEmail.test(input);
    };

    const checkPhoneNumberInStages = input => {
        // easy check 1:
        if (input === '') return true;

        // easy check 2: correct length of provided input
        if (!checkValidInputLength(input, MAX_PHONE_STRING_LEN)) return false;

        // harder check: make sure there are at least 10 digits
        const phoneNumber = input.replace(/\D/g, '');
        var isValidPhoneNumber = new RegExp('^\\d{10,' + MAX_PHONE_STRING_LEN + '}$');

        return isValidPhoneNumber.test(phoneNumber);
    };

    return (
        <ThemeProvider theme={theme}>
            <ValidationGroup>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <img src={'./RepairLogic_Logo.svg'} alt="RepairLogic Logo" />
                        <div className="d-flex flex-row align-items-center">
                            <Typography component="h1" variant="h5" style={{ marginRight: '0.5em' }}>
                                Shop Referral
                            </Typography>
                            <Tooltip
                                title="Please complete this form to quickly connect a shop with the RepairLogic sales team. Complete and accurate information is required in order to qualify for incentives."
                                enterTouchDelay={0}
                                leaveDelay={1200}>
                                <HelpOutlineIcon />
                            </Tooltip>
                        </div>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Validate
                                        name="coachNameValidator"
                                        reference={linkedRef}
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        custom={[
                                            value => checkValidInputLength(value, MAX_NAME_STRING_LEN),
                                            `Coach name must be less than ${MAX_NAME_STRING_LEN} characters`,
                                        ]}
                                        required={[true, 'Coach Name Required']}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="coachName"
                                            label="Coach Name"
                                            name="coachName"
                                            autoComplete="name"
                                            value={coachName}
                                            onChange={event => setCoachName(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="coachEmailValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        required={[true, 'Coach Email Required']}
                                        custom={[value => checkEmailInvalidInStages(value), 'Invalid Email Format']}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="Coach Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={coachEmail}
                                            onChange={event => setCoachEmail(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="shopNameValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        required={[true, 'Shop Name Required']}
                                        custom={[
                                            value => checkValidInputLength(value, MAX_NAME_STRING_LEN),
                                            `Shop name must be less than ${MAX_NAME_STRING_LEN} characters`,
                                        ]}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="shopName"
                                            label="Shop Name"
                                            name="shopName"
                                            value={shopName}
                                            onChange={event => setShopName(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={theme.formControl} required fullWidth>
                                        <InputLabel>Shop State</InputLabel>
                                        <Select
                                            input={
                                                shopState !== '' ? (
                                                    <OutlinedInput notched label={'Shop State'} />
                                                ) : undefined
                                            }
                                            value={shopState}
                                            onChange={event => setShopState(event.target.value)}
                                            error={skippedShopState}
                                            id="shopState">
                                            {helpers.stateOptions.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {skippedShopState && (
                                        <FormHelperText error={true}>Shop State Required</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="shopCityValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        required={[true, 'Shop Name Required']}
                                        custom={[
                                            value => checkValidInputLength(value, MAX_NAME_STRING_LEN),
                                            `Shop city must be less than ${MAX_NAME_STRING_LEN} characters`,
                                        ]}>
                                        <TextField
                                            fullWidth
                                            id="shopCity"
                                            label="Shop City"
                                            name="shopCity"
                                            value={shopCity}
                                            onChange={event => setShopCity(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="contactNameValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        required={[true, 'Shop Contact Name Required']}
                                        custom={[
                                            value => checkValidInputLength(value, MAX_NAME_STRING_LEN),
                                            `Contact name must be less than ${MAX_NAME_STRING_LEN} characters`,
                                        ]}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="contactName"
                                            name="contactName"
                                            label="Shop Contact Name"
                                            value={contactName}
                                            onChange={event => setContactName(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="contactEmailValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        required={[true, 'Shop Contact Email Required']}
                                        custom={[value => checkEmailInvalidInStages(value), 'Invalid Email Format']}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="contactEmail"
                                            label="Shop Contact Email Address"
                                            name="contactEmail"
                                            value={contactEmail}
                                            onChange={event => setContactEmail(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <Validate
                                        name="contactPhoneValidator"
                                        validation={formIsUntouched ? 'silent' : 'noisy'}
                                        custom={[
                                            value => checkPhoneNumberInStages(value),
                                            'Incomplete / Invalid Phone Number',
                                        ]}>
                                        <TextField
                                            fullWidth
                                            type="phone"
                                            id="contactPhone"
                                            label="Shop Contact Phone Number"
                                            name="contactPhone"
                                            value={contactPhone}
                                            onChange={event => setContactPhone(event.target.value)}
                                        />
                                    </Validate>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        id="additionalDetails"
                                        label="Additional Details"
                                        name="additionalDetails"
                                        value={additionalDetails}
                                        onChange={event => setAdditionalDetails(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="w-100 d-flex align-items-center flex-row">
                                            <div className="w-100 d-flex justify-content-center">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color="primary"
                                                            id="availableNowCheckBox"
                                                            defaultChecked={false}
                                                            onChange={() => {
                                                                if (specifyTime)
                                                                    document
                                                                        .getElementById('specifyTimeCheckBox')
                                                                        .click();
                                                                setAvailableNow(!availableNow);
                                                            }}
                                                        />
                                                    }
                                                    label="Available for demo now"
                                                />
                                            </div>
                                            {!availableNow && (
                                                <div className="w-100 d-flex flex-column justify-content-end">
                                                    <DatePicker
                                                        label="Demo Date"
                                                        dateFormat="DD-MM-YYYY"
                                                        slotProps={{ textField: { fullWidth: true } }}
                                                        value={shopAvailabilityDate}
                                                        onChange={event => setShopAvailabilityDate(dayjs(event['$d']))}
                                                        name="availabilityDate"
                                                        // note the error prop doesn't even work for this component, not an oversight :(
                                                    />

                                                    <Typography
                                                        variant="subtitle1"
                                                        display="block"
                                                        gutterBottom
                                                        color={'#FF5349'}>
                                                        {!shopAvailabilityDate.isSame(now) &&
                                                            !availableNow &&
                                                            (filledAllRequiredExceptDate ||
                                                                (shopAvailabilityDate.isBefore(now) &&
                                                                    !isUsingDefaultDate)) && ( // non-future date
                                                                <FormHelperText
                                                                    // don't show red text in the case the user hasn't selected an invalid date, but keep the reminder.
                                                                    style={
                                                                        isUsingDefaultDate
                                                                            ? { color: '#1976d2' }
                                                                            : { color: '#cc414c' }
                                                                    }>
                                                                    Select a date in the future
                                                                </FormHelperText>
                                                            )}
                                                    </Typography>
                                                    {!isUsingDefaultDate && !shopAvailabilityDate.isBefore(now) && (
                                                        <div className="w-100 d-flex align-items-center flex-row justify-content-center">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        id="specifyTimeCheckBox"
                                                                        defaultChecked={false}
                                                                        onChange={() => setSpecifyTime(!specifyTime)}
                                                                    />
                                                                }
                                                                label="Specify time"
                                                            />
                                                            {specifyTime && (
                                                                <TimePicker
                                                                    fullWidth
                                                                    slotProps={{ textField: { fullWidth: true } }}
                                                                    label="Demo Time"
                                                                    value={shopAvailabilityDate}
                                                                    onChange={event =>
                                                                        setShopAvailabilityDate(dayjs(event['$d']))
                                                                    }
                                                                    className="mt-1"
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <AutoDisabler firstDisplayErrors={true}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={formIsInvalid}
                                    sx={{ mt: 3, mb: 2 }}>
                                    Submit
                                </Button>
                            </AutoDisabler>
                            <div className="d-flex justify-content-center">
                                <Typography
                                    variant="subtitle1"
                                    display="block"
                                    gutterBottom
                                    style={{ color: userMessageColour }}
                                    id="userMessage">
                                    {userMessage}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Container>
            </ValidationGroup>
        </ThemeProvider>
    );
}
