import React from 'react';
import { useNavigate } from 'react-router-dom';
import apiConfig from './utils/ApiConfig';
import * as helpers from './utils/helpers';

export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const _apiSettings = React.useRef(undefined);
    const [token, setToken] = React.useState(null);
    const recaptchaRef = React.createRef();
    const [siteKey, setSiteKey] = React.useState('6LdkZGElAAAAAFS-m9g3m1eX1McNSd2FWo_2-J3o'); //real key as default

    const getSettings = async () => {
        if (!_apiSettings.current) {
            _apiSettings.current = await apiConfig.get();
            return _apiSettings.current;
        } else {
            return _apiSettings.current;
        }
    };

    //load the appropriate site key
    (async function () {
        const settings = await getSettings();

        //For developers, add a key that will render something in the localhost / any other environment without Configuration on the OEMiQ google account
        //NOTE: Just hard refresh if you get stuck!
        if (settings.applicationUrl.includes('localhost')) setSiteKey('6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI');

        const cachedToken = helpers.getCookie('apiToken');

        //skip robot check if the user has an API key already
        if (cachedToken && !token) await onLogin(cachedToken, true);
    })();

    async function attemptLoginAsync(password) {
        if (!password) return null; //ignore null inputs

        const settings = await getSettings();

        const url = `${settings.apiUrl}/api/security/SalesReferral/login`;
        const payload = {
            accessCode: password,
        };

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'token ' + recaptchaRef.current.getValue(),
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) return await response.text();

        return null;
    }

    const onLogin = async (key, skipReCaptcha = false) => {
        if (skipReCaptcha) {
            //trust that the API key is valid
            setToken(key);
            navigate('/referral');
            return true;
        } else {
            const token = await attemptLoginAsync(key);
            if (token) {
                setToken(token);
                document.cookie = `apiToken=${token}`;
                navigate('/referral');
                return true;
            }
        }

        return false;
    };

    const value = {
        token,
        onLogin,
        getSettings,
        siteKey,
        recaptchaRef,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
